import {
  Component,
  Inject,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
// import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { MaterialModule } from '../material/material.module';
import { environment } from '../../../../environments/environment';
import { NavigationService } from '../../data-access/navigation.service';
import { RequestService } from '../../data-access/request-service';
import { HttpClient } from '@angular/common/http';
// import { ChatService } from '../../services/ChatService.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  imports: [CommonModule, RouterModule, MaterialModule],
  standalone: true,
})
export class NavbarComponent implements AfterViewInit {
  loc_origin = location.origin;
  // base_url = environment.auth0credentials.authorizationParams.redirect_uri;
  // @Output() userSelectionEvent = new EventEmitter<string>();
  mobileNavToggle = false;
  requestService: RequestService<any>;
  subscriptionEndpoint = environment.BASE_URL + 'stripe/subscription';
  no_subscription = false;
  loading = false;
  site_url = '';

  public constructor(
    // @Inject(APP_BASE_HREF) public baseHref: string,
    private router: ActivatedRoute,
    private route: Router,
    public auth: AuthService,
    private navigationService: NavigationService,
    // private chatService: ChatService
    private http: HttpClient
  ) {
    this.requestService = new RequestService<any>(this.http);
  }
  ngAfterViewInit(): void {
    this.getSubscription();
  }

  onUserSelection(selection: string) {
    // this.userSelectionEvent.emit(selection);
  }

  toggleMobileMenu() {
    this.mobileNavToggle = this.mobileNavToggle ? false : true;
  }

  async getSubscription() {
    this.loading = true;
    try {
      this.requestService
        .simpleGetRequest(this.subscriptionEndpoint)
        .subscribe((res) => {
          const response = res as any;

          if (response.code === 200) {
            if (response.data === false) {
              this.no_subscription = true;
              this.loading = false;
              return;
            }

            this.site_url = response.data.site_url;

            this.loading = false;
          } else {
            console.log('Failed!', response);
            this.loading = false;
          }
        });
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  logout() {
    // this.chatService.clearMessages();
    this.auth.logout();
  }
}
